<template>
	<div class="Main width_1200 margin_auto">
		<div class="columns-herder">
			<img src="https://zy.metaera.media/assets/images/zl/zl_top.png" alt="" />
		</div>
		<div class="js-container js-content">
			<div class="js-main">
				<section class="js-main-recommended-column">
					<div class="js-recommended_nav_box">
						<ul>
							<li>
								<a class="a_isActivity">所有专栏</a>
							</li>
						</ul>
					</div>
					<div class="js_recommend_con_box" v-if="authorList.length > 0">
						<div
							class="js_recommend_con_list"
							v-for="(item, index) in authorList"
							:key="index"
							@click="
								$router.push({
									path: '/home_wz',
									query: { id: item.id },
								})
							"
						>
							<div class="js_recommend_con_list_top">
								<div class="js_recommend_con_list_top_img_box">
									<img
										v-if="item.avatar_img != '' && item.avatar_img != null"
										:src="item.avatar_img"
										:title="item.nickname"
									/>
									<img
										src="https://zy.metaera.media/user_header.png"
										alt=""
										v-else
									/>
									<img
										src="https://staticn.jinse.cn/w/img/8b3c17a.png"
										class="js-recommend_item-authentication"
									/>
								</div>
								<div class="js_recommend_con_list_top_author_box">
									<div
										class="author"
										v-if="item.nickname != '' && item.nickname != null"
									>
										{{ item.nickname }}
									</div>
									<div class="author" v-else>MetaEra用户 </div>
									<div class="article_count">
										文章：{{ item.num_articles }}篇
									</div>
								</div>
							</div>
							<div class="js_recommend_con_list_bottom">
								{{ item.desc }}
							</div>
						</div>
					</div>

					<div style="padding-bottom: 50px"> </div>
				</section>
			</div>
		</div>
	</div>
</template>

<script>
import { getAuthorList } from "@/api/home";
export default {
	name: "",
	data() {
		return {
			authorList: [],
			page: 1,
			size: 25,
			total: "",
		};
	},
	methods: {
		getAuthorList() {
			getAuthorList()
				.then((res) => {
					if (res.code == 200) {
						this.authorList = res.data;
						this.total = res.data.total;
					}
				})
				.catch((error) => {
					// this.$message.error(error.message);
				});
		},
	},
	created() {
		this.getAuthorList();
	},
};
</script>

<style scoped>
.js-loading {
	margin: 0 auto;
	display: flex;
	justify-content: center;
	align-items: center;
}

.js-loading .js-loadMoreBtn {
	border-radius: 14px;
	text-align: center;
	cursor: pointer;
	font-style: normal;
	font-weight: 400;
	padding-left: 21px;
	padding-right: 21px;
	margin: 0 13px;
	transition: all 0.1;
	background: #f9f9f9;
	color: #a0a0a9;
}

.js-loading .js-loadMoreBtn,
.js-loading .js-no-more {
	height: 28px;
	line-height: 28px;
	box-sizing: border-box;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 12px;
}

.js-loading .js-no-more {
	width: 110px;
	margin: 0 21px;
	background: #fff;
	border: 1px solid #f9f9f9;
	border-radius: 14px;
	color: #dedee1;
}

.js-loading > p {
	flex: 1;
	border-bottom: 1px solid #f9f9f9;
}

.js-list-loading {
	margin-top: 15px;
	text-align: center;
	font-size: 18px;
	height: 48px;
	padding-top: 10px;
	box-sizing: border-box;
}

.js-list-loading div {
	width: 12px;
	height: 12px;
	margin-left: 10px;
	background: #999;
	border-radius: 100%;
	display: inline-block;
	-webkit-animation: bouncedelay 1.4s ease-in-out infinite;
	animation: bouncedelay 1.4s ease-in-out infinite;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
}

.js-list-loading div:first-child {
	-webkit-animation-delay: -0.32s;
	animation-delay: -0.32s;
}

.js-list-loading div:nth-child(2) {
	-webkit-animation-delay: -0.16s;
	animation-delay: -0.16s;
}

@-webkit-keyframes bouncedelay {
	0%,
	80%,
	to {
		transform: scale(0);
		-webkit-transform: scale(0);
	}

	40% {
		transform: scale(1);
		-webkit-transform: scale(1);
	}
}

@keyframes bouncedelay {
	0%,
	80%,
	to {
		transform: scale(0);
		-webkit-transform: scale(0);
	}

	40% {
		transform: scale(1);
		-webkit-transform: scale(1);
	}
}

.js-no-more {
	text-align: center;
	font-size: 16px;
}

a {
	transition: all 0.1s;
}

.js-main {
	margin: 0 auto;
	width: 1170px;
}

.js-main:after {
	content: ".";
	display: block;
	height: 0;
	clear: both;
	visibility: hidden;
}

.js-main-recommended-column {
	margin: 0 auto;
	width: 1170px;
	min-height: 500px;
}

.js-main-recommended-column:after {
	content: ".";
	display: block;
	height: 0;
	clear: both;
	visibility: hidden;
}

.js-main-recommended-column .js-recommended_nav_box ul {
	width: 100%;
	height: 40px;
	display: flex;
	justify-content: flex-start;
	border-bottom: 1px solid #f5f5f5;
	box-sizing: border-box;
}

.js-main-recommended-column .js-recommended_nav_box ul li a {
	display: inline-block;
	height: 40px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-style: normal;
	font-weight: 400;
	font-size: 18px;
	line-height: 18px;
	cursor: pointer;
	box-sizing: border-box;
	margin-right: 21px;
	color: #767680;
}

.js-main-recommended-column .js-recommended_nav_box ul li .a_isActivity,
.js-main-recommended-column .js-recommended_nav_box ul li a:hover {
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 20px;
	font-weight: 600;
	line-height: 16px;
	letter-spacing: 0em;
	/* 黑色主色（字段一级） */
	color: #333333;
}

.js_recommend_con_box {
	padding-top: 10px;
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
	width: 960px;
}

.js_recommend_con_box .js_recommend_con_list {
	margin-right: 40px;
	margin-bottom: 40px;
	cursor: pointer;
	width: 200px;
	height: 150px;
	border-radius: 8px;
	opacity: 1;
	background: #ffffff;
	box-shadow: 6.43px 21.04px 35px 0px rgba(54, 62, 147, 0.051);
}

.js_recommend_con_box .js_recommend_con_list:hover {
	box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.js_recommend_con_box .js_recommend_con_list .js_recommend_con_list_top {
	height: 67px;
	display: flex;
}

.js_recommend_con_box
	.js_recommend_con_list
	.js_recommend_con_list_top
	.js_recommend_con_list_top_img_box {
	width: 50px;
	height: 50px;
	border-radius: 8px;
	opacity: 1;
	margin-left: 11px;
	margin-top: 15px;
	border: 1px solid #ededed;
	-webkit-filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.5));
	filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.5));
	position: relative;
}

.js_recommend_con_box
	.js_recommend_con_list
	.js_recommend_con_list_top
	.js_recommend_con_list_top_img_box
	img {
	width: 50px;
	height: 50px;
	border-radius: 8px;
}

.js_recommend_con_box
	.js_recommend_con_list
	.js_recommend_con_list_top
	.js_recommend_con_list_top_img_box
	.js-recommend_item-authentication {
	width: 18px;
	height: 18px;
	position: absolute;
	top: 37px;
	left: 50%;
	transform: translateX(50%);
}

.js_recommend_con_box
	.js_recommend_con_list
	.js_recommend_con_list_top
	.js_recommend_con_list_top_author_box {
	margin-left: 13px;
	flex: 1;
	height: 55px;
}

.js_recommend_con_box
	.js_recommend_con_list
	.js_recommend_con_list_top
	.js_recommend_con_list_top_author_box
	.author {
	margin-top: 18px;
	height: 18px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-style: normal;
	font-size: 16px;
	font-weight: 600;
	line-height: 18px;
	letter-spacing: 0em;
	color: #000000;
	overflow: hidden;
	display: -webkit-box;
	-webkit-line-clamp: 1;
	text-overflow: ellipsis;
	-webkit-box-orient: vertical;
}

.js_recommend_con_box
	.js_recommend_con_list
	.js_recommend_con_list_top
	.js_recommend_con_list_top_author_box
	.article_count {
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-style: normal;
	font-weight: 400;
	margin-top: 13px;
	font-size: 13px;
	line-height: 13px;
	color: #b2b2b2;
}

.js_recommend_con_box .js_recommend_con_list .js_recommend_con_list_bottom {
	height: 48px;
	margin-top: 20px;
	margin-left: 11px;
	margin-right: 11px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 16px;
	letter-spacing: 0em;

	/* 描述字段 */
	color: #656878;
	overflow: hidden;
	display: -webkit-box;
	-webkit-line-clamp: 3;
	text-overflow: ellipsis;
	-webkit-box-orient: vertical;
}

.js-loading {
	margin: 34px auto;
}

.js-loading .js-loadMoreBtn {
	width: 100%;
	height: 48px;
	border: 1px solid #f5f5f5;
	border-radius: 2px;
	background: #f5f5f5;
	text-align: center;
	cursor: pointer;
}

.js-loading .js-loadMoreBtn,
.js-loading .js-noData {
	display: flex;
	justify-content: center;
	align-items: center;
}

.js-loading .js-noData {
	flex-direction: column;
	min-height: 400px;
}

.js-loading .js-noData img {
	margin: 20px 0;
}

.js-loading .js-noData span {
	font-size: 15px;
	color: #999;
}

.js-list-loading {
	margin-top: 15px;
	text-align: center;
	font-size: 18px;
	height: 48px;
	padding-top: 10px;
	box-sizing: border-box;
}

.js-list-loading div {
	width: 12px;
	height: 12px;
	margin-left: 10px;
	background: #999;
	border-radius: 100%;
	display: inline-block;
	-webkit-animation: bouncedelay1.4s ease-in-out infinite;
	animation: bouncedelay1.4s ease-in-out infinite;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
}

.js-list-loading div:first-child {
	-webkit-animation-delay: -0.32s;
	animation-delay: -0.32s;
}

.js-list-loading div:nth-child(2) {
	-webkit-animation-delay: -0.16s;
	animation-delay: -0.16s;
}

@-webkit-keyframes bouncedelay {
	0%,
	80%,
	to {
		transform: scale(0);
		-webkit-transform: scale(0);
	}

	40% {
		transform: scale(1);
		-webkit-transform: scale(1);
	}
}

@keyframes bouncedelay {
	0%,
	80%,
	to {
		transform: scale(0);
		-webkit-transform: scale(0);
	}

	40% {
		transform: scale(1);
		-webkit-transform: scale(1);
	}
}

.js-no-more {
	text-align: center;
	font-size: 16px;
}

.js-web .js-content {
	margin-bottom: 55px;
}

.columns-herder {
	width: 920px !important;
	height: 240px;
	margin-bottom: 34px;
	position: relative;
}

.columns-herder img {
	width: 100%;
	/* height: 100%; */
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
}

.columns-herder .columns-logo {
	width: 136px;
	height: 40px;
	top: 44px;
}

.columns-herder .columns-logo,
.columns-herder p {
	position: absolute;
	left: 50%;
	transform: translate(-50%);
}

.columns-herder p {
	bottom: 44px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 16px;
	line-height: 16px;
	color: #a0a0a9;
}
@media screen and (max-width: 950px) {
	.columns-herder {
		width: 100%;
		height: 240px;
		margin-bottom: 20px;
	}
	.columns-herder img {
		width: 100%;
		object-fit: cover;
	}
	.js-main-recommended-column {
		margin: 0 auto;
		width: 100%;
		min-height: 500px;
	}
	.js-recommended_nav_box {
		margin-left: 20px;
	}
	.js_recommend_con_box {
		padding-top: 14px;
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-start;
		width: 100%;
	}
	.js_recommend_con_box .js_recommend_con_list {
		width: 46%;
		height: 177px;
		background: #fff;
		border: 1px solid #f5f5f5;
		border-radius: 8px;
		margin-left: 2%;
		margin-right: 2%;
		margin-bottom: 14px;
		/* padding: 21px 20px 18px; */
		box-sizing: border-box;
		cursor: pointer;
		transition: all 0.2s;
	}
	.a_isActivity {
		font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
			"Microsoft YaHei", Arial, sans-serif;
		font-size: 20px;
		font-weight: 600;
		line-height: 16px;
		letter-spacing: 0em;

		/* 黑色主色（字段一级） */
		color: #333333 !important;
	}
	.columns-herder {
		width: 100% !important;
		height: 240px;
		margin-bottom: 34px;
		position: relative;
	}

	.columns-herder img {
		width: 100%;
		height: auto;
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
	}
}
</style>
